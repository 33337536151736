<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5 8C5 7.44772 5.44772 7 6 7H26C26.5523 7 27 7.44772 27 8C27 8.55228 26.5523 9 26 9H6C5.44772 9 5 8.55228 5 8Z"
		></path>
		<path
			d="M5 16C5 15.4477 5.44772 15 6 15H26C26.5523 15 27 15.4477 27 16C27 16.5523 26.5523 17 26 17H6C5.44772 17 5 16.5523 5 16Z"
		></path>
		<path
			d="M6 23C5.44772 23 5 23.4477 5 24C5 24.5523 5.44772 25 6 25H26C26.5523 25 27 24.5523 27 24C27 23.4477 26.5523 23 26 23H6Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconMenu",
});
</script>
